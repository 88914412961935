import React from "react"

import contactFormStyles from "../../components/contactForm/contactForm.module.scss"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"

export default function Contact() {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [submitted, setSubmitted] = React.useState(false)
  const [subject] = React.useState(
    "Form submission from Clean Air Filter Testimonial form"
  )

  const sendEmail = e => {
    e.preventDefault()

    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "testimonial",
        name,
        subject,
        email,
        phone,
        message,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Submit Your Testimonial | Clean Air Filter</title>
      </Helmet>
      <section className="contact-links">
        <h1 style={{ width: "100%" }}>
          <span className="tail">
            <span className="head"></span>Submit Your Testimonial
          </span>
        </h1>

        {/* <span style={{ display: 'flex', flexWrap: 'wrap' }}> */}
        <article className="w-60">
          {submitted ? (
            <p className="form-feedback">
              We received your message! A member of our team will touch base
              with you soon. Thank you!
            </p>
          ) : (
            <form
              name="testimonial"
              // style={{ minWidth: 375 }}
              data-netlify={true}
              netlify-honeypot="bot-field"
              method="post"
              className={contactFormStyles.wrapper}
              onSubmit={sendEmail}
            >
              <div style={{ display: "none" }}>
                <input
                  type="text"
                  id="inputFirstName"
                  name="bot-field"
                  aria-label="name"
                ></input>
              </div>

              <div style={{ display: "none" }}>
                <input
                  type="hidden"
                  id="inputSubject"
                  name="subject"
                  aria-label="subject"
                  value={subject}
                ></input>
              </div>
              {/* <div style={{ display: 'none' }}>
                        <input type="hidden" id="inputFormName" name="formName" aria-label="formName" value={"testimonial"}></input>
                    </div> */}

              <div>
                <label htmlFor="inputName">Name</label>
                <input
                  type="text"
                  id="inputName"
                  aria-label="inputName"
                  name="name"
                  value={name}
                  onChange={e => {
                    setName(e.currentTarget.value)
                  }}
                  required
                ></input>
              </div>

              <div>
                <label htmlFor="inputEmail">Email</label>
                <input
                  type="email"
                  id="inputEmail"
                  aria-label="inputEmail"
                  name="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.currentTarget.value)
                  }}
                  required
                ></input>
              </div>

              <div>
                <label htmlFor="inputPhone">Phone</label>
                <input
                  id="phone"
                  id="inputPhone"
                  aria-label="inputPhone"
                  name="phone"
                  value={phone}
                  onChange={e => {
                    setPhone(e.currentTarget.value)
                  }}
                ></input>
              </div>

              <div>
                <label htmlFor="inputMessage">Message</label>
                <textarea
                  rows={4}
                  id="inputMessage"
                  aria-label="inputMessage"
                  name="message"
                  value={message}
                  onChange={e => {
                    setMessage(e.currentTarget.value)
                  }}
                ></textarea>
              </div>
              <button type="submit" className="primary red">
                Send
              </button>
            </form>
          )}
        </article>
        {/* </span> */}
      </section>
    </Layout>
  )
}
